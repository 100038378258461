import React from "react";

import { RetirementProvider } from "./src/context/RetirementContext";
import { initAnalytics, analytics } from './src/services/analytics';
import "./src/styles/index.scss";

export const onInitialClientRender = () => {
  initAnalytics();
}

export const wrapRootElement = ({ element }) => {
  return <RetirementProvider>{element}</RetirementProvider>
};

export const onRouteUpdate = ({ location, prevLocation }) => {
  analytics().pageView({
    $current_url: location.pathname,
  });
};
