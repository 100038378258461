import posthog from "posthog-js";

// Configuration for PostHog
const config = {
  apiKey: process.env.GATSBY_POSTHOG_API_KEY || "",
  apiHost: process.env.GATSBY_POSTHOG_API_HOST,
};

// Initialize PostHog
export const initAnalytics = () => {
  // Only run on the client side
  if (typeof window === "undefined") return;

  posthog.init(config.apiKey, {
    api_host: config.apiHost,
    autocapture: true,
  });
};

export const analytics = () => {
  return {
    track: (eventName: string, properties: Record<string, any> = {}) => {
      // Only run on client side
      if (typeof window === "undefined") return;

      if (typeof posthog !== "undefined") {
        posthog.capture(eventName, properties);
      }
    },

    identify: (userId: string, traits: Record<string, any> = {}) => {
      // Only run on client side
      if (typeof window === "undefined") return;

      if (typeof posthog !== "undefined") {
        posthog.identify(userId, traits);
      }
    },

    pageView: (properties = {}) => {
      // Only run on client side
      if (typeof window === "undefined") return;

      const url = typeof window !== "undefined" ? window.location.pathname : "";

      if (typeof posthog !== "undefined") {
        posthog.capture("$pageview", {
          $current_url: url,
          ...properties,
        });
      }
    },
  };
};

export const useAnalytics = () => {
  return analytics();
};

export default analytics;
