exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-calculator-tsx": () => import("./../../../src/pages/calculator.tsx" /* webpackChunkName: "component---src-pages-calculator-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-next-step-tsx": () => import("./../../../src/pages/next-step.tsx" /* webpackChunkName: "component---src-pages-next-step-tsx" */),
  "component---src-pages-pdpa-tsx": () => import("./../../../src/pages/pdpa.tsx" /* webpackChunkName: "component---src-pages-pdpa-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-result-tsx": () => import("./../../../src/pages/result.tsx" /* webpackChunkName: "component---src-pages-result-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */)
}

