// https://docs.google.com/spreadsheets/d/19yqzqSNP_ahVry73LJgGZFeGeE6r5u3V/edit?gid=1852113784#gid=1852113784
// formula is based on the spreadsheet above...

import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useEffect,
} from "react";

interface RetirementContextType {
  // User inputs
  gender: string | null;
  currentAge: number;
  retirementAge: number;
  lifeExpectancy: number;
  monthlyIncome: number;
  epfBalance: number;
  currentSavings: number;
  investmentGrowthRate: number; // Original input value
  resultInvestmentGrowthRate: number; // For result page adjustments
  monthlyExpenseInRetirement: number;

  // Results
  retirementGoal: number;
  monthlySavingsNeeded: number;
  isCalculated: boolean;

  // Debug values
  debugValues: {
    epfEmployeeYearlyContribution: number;
    epfEmployerYearlyContribution: number;
    epfTotalYearlyContribution: number;
    adjustedReturnForEpf: number;
    projectedEpfValue: number;
    adjustedSavings: number;
    estimatedYearlyExpenses: number;
    adjustedReturn: number;
    shortfall: number;
  };

  // Actions
  setGender: (gender: string | null) => void;
  setCurrentAge: (age: number) => void;
  setRetirementAge: (age: number) => void;
  setLifeExpectancy: (age: number) => void;
  setMonthlyIncome: (amount: number) => void;
  setEpfBalance: (amount: number) => void;
  setCurrentSavings: (amount: number) => void;
  setInvestmentGrowthRate: (rate: number) => void;
  setResultInvestmentGrowthRate: (rate: number) => void; // New setter for result page
  setMonthlyExpenseInRetirement: (amount: number) => void;
  calculateRetirement: () => void;
  resetCalculation: () => void;
}

const RetirementContext = createContext<RetirementContextType | null>(null);

export const useRetirement = () => {
  const context = useContext(RetirementContext);
  if (!context) {
    throw new Error("useRetirement must be used within a RetirementProvider");
  }
  return context;
};

interface RetirementProviderProps {
  children: ReactNode;
}

// Financial calculation utilities
const FV = (
  rate: number,
  nper: number,
  pmt: number = 0,
  pv: number = 0,
  type: number = 0
): number => {
  // Future Value calculation
  if (rate === 0) return -pv - pmt * nper;

  const term = Math.pow(1 + rate, nper);

  if (type === 1) {
    return -pv * term - (pmt * (1 + rate) * (term - 1)) / rate;
  }

  return -pv * term - (pmt * (term - 1)) / rate;
};

const PV = (
  rate: number,
  nper: number,
  pmt: number = 0,
  fv: number = 0,
  type: number = 0
): number => {
  // Present Value calculation
  if (rate === 0) return -fv - pmt * nper;

  const term = Math.pow(1 + rate, nper);

  if (type === 1) {
    return (-fv - (pmt * (1 + rate) * (term - 1)) / rate) / term;
  }

  return (-fv - (pmt * (term - 1)) / rate) / term;
};

const PMT = (
  rate: number,
  nper: number,
  pv: number = 0,
  fv: number = 0,
  type: number = 0
): number => {
  // Payment calculation
  if (rate === 0) return (-pv - fv) / nper;

  const term = Math.pow(1 + rate, nper);

  if (type === 1) {
    return (-fv - pv * term) / (((1 + rate) * (term - 1)) / rate);
  }

  return (-fv - pv * term) / ((term - 1) / rate);
};

export const RetirementProvider = ({ children }: RetirementProviderProps) => {
  // Constants for calculations (hidden from user)
  const INFLATION_RATE = 0.03; // 3%
  const INCOME_ANNUAL_INCREMENT = 0.05; // 5%
  const EPF_EMPLOYEE_RATE = 0.11; // 11%
  const EPF_DIVIDEND_RATE = 0.055; // 5.5%

  // States for user inputs
  const [gender, setGender] = useState<string | null>(null);
  const [currentAge, setCurrentAge] = useState(21);
  const [retirementAge, setRetirementAge] = useState(55);
  const [lifeExpectancy, setLifeExpectancy] = useState(77.8);
  const [monthlyIncome, setMonthlyIncome] = useState(0);
  const [epfBalance, setEpfBalance] = useState(0);
  const [currentSavings, setCurrentSavings] = useState(0);
  const [investmentGrowthRate, setInvestmentGrowthRate] = useState(4);
  // New separate state for result page
  const [resultInvestmentGrowthRate, setResultInvestmentGrowthRate] =
    useState(4);
  const [monthlyExpenseInRetirement, setMonthlyExpenseInRetirement] =
    useState(0);

  // States for calculation results
  const [retirementGoal, setRetirementGoal] = useState(0);
  const [monthlySavingsNeeded, setMonthlySavingsNeeded] = useState(0);
  const [isCalculated, setIsCalculated] = useState(false);
  const [fixedShortfall, setFixedShortfall] = useState(0);

  // Debug values
  const [debugValues, setDebugValues] = useState({
    epfEmployeeYearlyContribution: 0,
    epfEmployerYearlyContribution: 0,
    epfTotalYearlyContribution: 0,
    adjustedReturnForEpf: 0,
    projectedEpfValue: 0,
    adjustedSavings: 0,
    estimatedYearlyExpenses: 0,
    adjustedReturn: 0,
    shortfall: 0,
  });

  // Calculation logic based on spreadsheet formulas
  const performCalculation = (forceRecalculateAll = false) => {
    // Calculate based on employer contribution rate, which varies by salary
    const EPF_EMPLOYER_RATE = monthlyIncome < 5000 ? 0.13 : 0.12; // 13% if below RM5000, 12% if above

    // 1. Calculate years until retirement and years in retirement
    const yearsUntilRetirement = retirementAge - currentAge;
    const yearsInRetirement = lifeExpectancy - retirementAge;

    // For initial calculations, use the original investmentGrowthRate
    // For result page adjustments, use resultInvestmentGrowthRate
    const rateToUse =
      isCalculated && !forceRecalculateAll
        ? resultInvestmentGrowthRate
        : investmentGrowthRate;

    // If already calculated and not forcing recalculation, only update monthly savings
    if (isCalculated && !forceRecalculateAll) {
      // Calculate monthly savings needed using the shortfall and current result growth rate
      // This matches row 37: PMT(C35,C6-C5,,C34*-1,0)/12
      const calculatedMonthlySavingsNeeded =
        PMT(
          resultInvestmentGrowthRate / 100, // Use result growth rate here
          yearsUntilRetirement,
          0,
          fixedShortfall * -1,
          0
        ) / 12;

      // Only update the monthly savings needed, not the retirement goal
      setMonthlySavingsNeeded(calculatedMonthlySavingsNeeded);
      return;
    }

    // Yearly EPF contributions (rows 24-26)
    const epfEmployeeYearlyContribution =
      monthlyIncome * EPF_EMPLOYEE_RATE * 12;
    const epfEmployerYearlyContribution =
      monthlyIncome * EPF_EMPLOYER_RATE * 12;
    const epfTotalYearlyContribution =
      epfEmployeeYearlyContribution + epfEmployerYearlyContribution;

    // Adjusted return for EPF (row 27) = (EPF Dividend Rate - Inflation) / (1 + Inflation)
    const adjustedReturnForEpf =
      (EPF_DIVIDEND_RATE - INCOME_ANNUAL_INCREMENT) /
      (1 + INCOME_ANNUAL_INCREMENT);

    // Calculate projected EPF value at retirement (row 28)
    // =-FV(C21,C6-C5,,-PV(C27,C6-C5,C26,,1))+FV(C21,C6-C5,,C12)*-1
    const pvEpfContributions = PV(
      adjustedReturnForEpf,
      yearsUntilRetirement,
      epfTotalYearlyContribution,
      0,
      1
    );
    const projectedEpfValue =
      -FV(EPF_DIVIDEND_RATE, yearsUntilRetirement, 0, -pvEpfContributions) +
      FV(EPF_DIVIDEND_RATE, yearsUntilRetirement, 0, epfBalance) * -1;

    // Adjusted savings (row 31) = -FV(investment_rate, years_until_retirement, 0, current_savings)
    // Always use the original input growth rate for this calculation
    const realInvestmentRate = investmentGrowthRate / 100;
    const adjustedSavings = -FV(
      realInvestmentRate,
      yearsUntilRetirement,
      0,
      currentSavings
    );

    // Estimated yearly expenses at retirement (row 32)
    // =-FV(inflation_rate, years_until_retirement, 0, monthly_expense * 12) / 12 * 12
    const estimatedYearlyExpenses =
      (-FV(
        INFLATION_RATE,
        yearsUntilRetirement,
        0,
        monthlyExpenseInRetirement * 12
      ) /
        12) *
      12;

    // Adjusted return (row 33) = (1 + investment_rate) / (1 + inflation_rate) - 1
    // For initial calculation, use the original input growth rate
    // For recalculations, use the result growth rate
    const rateForCalculation =
      isCalculated && !forceRecalculateAll
        ? resultInvestmentGrowthRate / 100
        : realInvestmentRate;

    const adjustedReturn = (1 + rateForCalculation) / (1 + INFLATION_RATE) - 1;

    // Calculate retirement goal (row 36)
    // =-PV(adjusted_return, years_in_retirement, yearly_expenses, 0, 1)
    const calculatedRetirementGoal = -PV(
      adjustedReturn,
      yearsInRetirement,
      estimatedYearlyExpenses,
      0,
      1
    );

    // Calculate shortfall (row 34) = retirement_goal - projected_epf - adjusted_savings
    const shortfall =
      calculatedRetirementGoal - projectedEpfValue - adjustedSavings;

    // Store shortfall for later recalculations when investment rate changes
    setFixedShortfall(shortfall);

    // Calculate monthly savings needed (row 37)
    // =PMT(investment_growth_rate, years_until_retirement, 0, shortfall, 0) / 12
    // Use the appropriate growth rate depending on whether this is initial or recalculation
    const rateForSavings =
      isCalculated && !forceRecalculateAll
        ? resultInvestmentGrowthRate / 100
        : investmentGrowthRate / 100;

    const initialCalculatedMonthlySavingsNeeded =
      PMT(rateForSavings, yearsUntilRetirement, 0, shortfall * -1, 0) / 12;

    // Update debug values
    setDebugValues({
      epfEmployeeYearlyContribution,
      epfEmployerYearlyContribution,
      epfTotalYearlyContribution,
      adjustedReturnForEpf,
      projectedEpfValue,
      adjustedSavings,
      estimatedYearlyExpenses,
      adjustedReturn,
      shortfall,
    });

    // Update state with calculated results
    setRetirementGoal(Math.round(calculatedRetirementGoal));
    setMonthlySavingsNeeded(Math.round(initialCalculatedMonthlySavingsNeeded));
  };

  // Public function to calculate retirement
  const calculateRetirement = () => {
    // Validate required inputs
    if (!gender || monthlyIncome <= 0 || monthlyExpenseInRetirement <= 0) {
      alert("Please fill in all required fields");
      return;
    }

    // When first calculating, initialize result investment growth rate to match the input
    setResultInvestmentGrowthRate(investmentGrowthRate);

    performCalculation(true); // Force full recalculation
    setIsCalculated(true);
  };

  // Function to reset the calculation results but preserve all inputs
  const resetCalculation = () => {
    setIsCalculated(false);
    // We're deliberately NOT resetting any input values
  };

  // Recalculate when result investment growth rate changes after initial calculation
  useEffect(() => {
    if (isCalculated) {
      performCalculation();
    }
  }, [resultInvestmentGrowthRate]); // Changed from investmentGrowthRate to resultInvestmentGrowthRate

  // Set life expectancy based on gender selection, but only if it hasn't been manually changed
  useEffect(() => {
    // Only update life expectancy if it matches expected defaults
    if (gender === "male" && lifeExpectancy === 77.8) {
      setLifeExpectancy(73); // Men average life expectancy
    } else if (gender === "female" && lifeExpectancy === 73) {
      setLifeExpectancy(77.8); // Women average life expectancy
    }
  }, [gender, lifeExpectancy]);

  const value = {
    gender,
    currentAge,
    retirementAge,
    lifeExpectancy,
    monthlyIncome,
    epfBalance,
    currentSavings,
    investmentGrowthRate,
    resultInvestmentGrowthRate,
    monthlyExpenseInRetirement,
    retirementGoal,
    monthlySavingsNeeded,
    isCalculated,
    debugValues,
    setGender,
    setCurrentAge,
    setRetirementAge,
    setLifeExpectancy,
    setMonthlyIncome,
    setEpfBalance,
    setCurrentSavings,
    setInvestmentGrowthRate,
    setResultInvestmentGrowthRate,
    setMonthlyExpenseInRetirement,
    calculateRetirement,
    resetCalculation,
  };

  return (
    <RetirementContext.Provider value={value}>
      {children}
    </RetirementContext.Provider>
  );
};
